<template>
  <main id="sme-noticeinfo" class="page-container">
    <div class="page-header">
      <div class="page-component">
        <!-- 메뉴이동 로직 추가 S (20220714 - hib)  -->
        <div v-if="isMobile" class="box_select">
          <button type="button" id="select_btn" @click="click">소담스퀘어</button>
            <ul id="select_list">
              <li v-for="(item, idx) in menus[0].children" :key="idx"><a :href="item.path">- {{item.title}}</a></li>
            </ul>
        </div>
        <!-- 메뉴이동 로직 추가 E (20220714 - hib)  -->
        <!-- <div v-if="isMobile" class="section-notice-arrow">
          <a href="/studio" class="btn-arrow">
            <i class="icon-arrow-prev">이전 페이지</i>
          </a>
          <button type="button" class="btn-arrow">
            <a href="/sodam" class="btn-arrow">
              <i class="icon-arrow-next">다음 페이지</i>
            </a>
          </button>
        </div> -->
        <div class="page-header-title">
          <h2 class="title">소담스퀘어</h2>
        </div>

        <div class="tag-search">
          <div>
            <div class="form-group" @keyup.enter="$router.push({name:'Playground2', query:{tag: plygrndTag}})">
              <input v-model="plygrndTag" type="text" class="form-control" placeholder="태그검색">
              <input type="submit" class="screen-out">
            </div>
          </div>
        </div>

      </div>
    </div>
    <div class="page-body" data-fill="true">
      <nav class="page-nav">
        <ul class="nav-list page-component">
          <li class="nav-item" :class="{'is-selected': (plygrndCtgrDcd2 === '')}">
<!--            <router-link :to="{name:'Playground2'}"><span class="nav-text">전체</span></router-link>-->
            <router-link :to="{name:'Playground2'}"></router-link>
          </li>
          <li v-for="(item, idx) in ctgrItems" class="nav-item" :key="idx" :class="{'is-selected' : item.dcd === plygrndCtgrDcd2}">

            <router-link v-if="item.dcdVal !=='소담스퀘어 예약 바로가기'" :to="{name:'Playground2', query:{ctgrCode:item.dcd}}" class="nav-link"><span class="nav-text">{{ item.dcdVal }}</span></router-link>
            <router-link v-else to="/studio?platid=8"><span class="nav-text">소담스퀘어 예약 바로가기</span></router-link>
          </li>
        </ul>
      </nav>

      <div class="playground-title">
        <p v-if="$route.query.ctgrCode === '1012001'" class="playground-title-desc">
          소상공인이라면 누구나 무료로 스튜디오, 장비 이용이 가능하며, 홍보 콘텐츠, 라이브 방송 등 온라인 판로확대 프로그램 제작을 지원합니다.
        </p>
        <p v-else-if="$route.query.ctgrCode === '1012002'" class="playground-title-desc">
          소담스퀘어 관련 다양한 정보들을 이 곳에서 만나보세요.
        </p>
      </div>

      <common-list :div-class="['page-component']"
                   :is-loading="isLoading"
                   :is-no-result="isNoResult">
        <div class="post-list-wrapper notice-list-wrapper">
          <div class="list-header">
            <template v-if="isMobile">
              <div class="filter-selector">
                <select v-model="sortCd" name="" id="" class="form-control" required="" @change="selectSort">
                  <option value="">최신순</option>
                  <option value="I">인기순</option>
                </select>
              </div>
            </template>
            <template v-else>
              <div class="header-column">
                <!--<h3 class="title">전체 <span class="text-muted">{{ totalCount }}</span></h3>-->
              </div>
              <div class="header-column">
                <div class="filter-selector">
                  <select v-model="sortCd" name="" id="" class="form-select" required="" @change="selectSort">
                    <option value="">최신순</option>
                    <option value="I">인기순</option>
                  </select>
                </div>
              </div>
            </template>
          </div>
          <common-card :items="refineItems" :is-tag="true" />

          <div v-if="hasMore" class="page-buttons" data-align="center">
            <button class="btn btn-lg btn-outline-white" @click="morePage">
              <span class="text">더보기</span>
            </button>
          </div>

        </div>
      </common-list>
    </div>
  </main>
  <!-- end::page-container(#소상공인정보놀이터) -->
</template>

<script>
import {ACT_GET_COMMON_CODE_LIST, ACT_GET_PLAYGROUND2_LIST} from '../../../store/_act_consts';
import {getItems, lengthCheck, queryToValue, setPaging, viewTags} from '../../../assets/js/utils';
import CommonCard from '../../../components/common/CommonCard';
import {mapGetters} from 'vuex';
import NoResult from '../../../components/common/NoResult';
import CommonList from '../../../components/common/CommonList';
import menus from '../../../assets/js/menus';

export default {
  name: 'Playground2',
  components: {
    CommonCard,
    NoResult,
    CommonList
  },
  data: () => ({
    isLoading: false,
    hasMore: false,
    totalCount: 0,
    pageSize: 12,
    pageNo: 1,
    sortCd: '',
    plygrndTag: '',
    plygrndCtgrDcd2: '',
    items: [],
    ctgrItems: [],
    menus: menus.filter(x => (x.code === 'studio')), //모바일 중메뉴 데이터 (20220714 - hib)
  }),
  watch:{
    $route(to){
      this.plygrndCtgrDcd2 = queryToValue(to.query.ctgrCode, false, '');
      this.plygrndTag = queryToValue(to.query.tag, false);
      this.changeCtgr(this.plygrndCtgrDcd2);
    }
  },
  computed:{
    ...mapGetters('common', ['isMobile']),
    refineItems(){
      return this.items.map(x => ({
        plygrndId: x.plygrndId,
        ctgr: x.plygrndCtgr2,
        tit: x.plygrndTit,
        tags: viewTags(x, 'plygrndTag'),
        rprsImgPtUrl: x.rprsImgPtUrl,
        viewLink: (this.$route.query.ctgrCode ? `/playground2/${x.plygrndId}/view?ctgrCode=${this.$route.query.ctgrCode}`: `/playground2/${x.plygrndId}/view`)
      }));
    },
    isNoResult(){
      return this.items.length === 0;
    }
  },
  created() {
    this.plygrndCtgrDcd2 = queryToValue(this.$route.query.ctgrCode, false, '');
    this.plygrndTag = queryToValue(this.$route.query.tag, false);
    this.getPlayGroundCtgrList();
    this.getPlaygroundList(true);


  },
  methods: {
    getPlayGroundCtgrList(){
      this.$store.dispatch(`common/${ACT_GET_COMMON_CODE_LIST}`, {dcd: '1012', masterYn: 'N'}).then(res => {
        if(lengthCheck(res)) {
          this.ctgrItems = getItems(res);
          let test = this.ctgrItems;

        }
      }).catch(e => {
        console.error(e);
      });
    },
    getPlaygroundList(isInit) {
      if(isInit) {
        this.items = [];
        this.isLoading = true;
        this.pageNo = 1;
      }
      this.$store.dispatch(`studio/${ACT_GET_PLAYGROUND2_LIST}`, {
        plygrndTag: this.plygrndTag,
        plygrndCtgrDcd2: this.plygrndCtgrDcd2,
        sortCd: this.sortCd,
        pageNo: this.pageNo,
        pageSize: this.pageSize
      }).then(res=>{
        if(lengthCheck(res)){
          this.items = this.items.concat(getItems(res));
          setPaging(this, res);
        } else {
          setPaging(this, res);
        }
        this.isLoading = false;
      }).catch(e=>{
        console.error(e);
        this.isLoading = false;
      });
    },
    morePage(){
      if(this.hasMore) this.pageNo++;
      this.getPlaygroundList();
    },
    selectSort() {
      this.getPlaygroundList(true);
    },
    changeCtgr(dcd) {
      this.plygrndCtgrDcd2 = dcd;
      this.getPlaygroundList(true);
    },
    searchTagKeyword(){
      this.getPlaygroundList(true);
    },
    /* 중메뉴이동 (20220714 - hib) */
    click() {
      let element = document.getElementById('select_list');
      element.classList.toggle('show_list');
      let element02 = document.getElementById('select_btn');
      element02.classList.toggle('active');
    },
  }
};
</script>
